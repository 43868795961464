.socials-sharebutton,
.copied {
  transition: transform 2s;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  color: #344054;
  font-size: 16px;
}
.socials-sharebutton:hover {
  transform: translateY(-7px);
}
