.pagination{
    display: flex;
    justify-content: center
}
.pag-page-item{
    list-style-type: none;
}
.page-link{
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 30px;
}
.page-link-active{
    background-color: #d2120f;
    color: #fff;
}