.form_container {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

:root {
  --color-text: #464646;
  --testimonial-bg: #fbf9f9;
  --card-review: #f3f4f6;
}

/* [data-theme='dark'] {
  --testimonial-bg: #131b20;
  --color-text: #fff;
  --card-review: #3A3E43;
  transition: all 1s;
} */

.review_div {
  background-color: var(--testimonial-bg);
  color: var(--color-text);
  transition: all 1s;
}

.review-bg {
  background-color: var(--card-review);
  transition: all 1s;
}

.register_form_modal {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.form_field_modal {
  padding: 10px;
  font-size: 15px;
}

.username_error,
.message_error {
  font-size: 14px;
  /* color: #21ac2f; */
  color: #d2120f !important;
  margin-bottom: 10px;
}

.modal_form_input {
  background: #f2f2f2;
}
.modal_form_input:focus,
.modal_form_textarea:focus {
  outline: none;
  /* border: 1px solid #c97b7a; */
  /* box-shadow: 0px 0px 0px 4px #fbe7e7; */
}

/* .error {
  border-style: solid;
  border: 2px solid #ffa4a4;
} */

/* button:disabled {
  cursor: default;
} */

.close_btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.left_love {
  transform: rotate(0.5turn) !important;
}

.review_button {
  transform: translate(208px, 0);
  transition-duration: 700ms !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.review_button:hover {
  transform: translate(0, 0);
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 1000ms !important;
}
