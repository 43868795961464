.dashboard {
  width: 100%;
  padding: 6em 5.5em 2em;
  color: #464646;
}
.dashboard--view_btn-container{
    /* text-align: right; */
    margin-top: 0.5em;
    display: flex;
}
.dashboard--view_btn{
    border: none;
    background: none;
    color : #292D32;
    /* color: #d2120f; */
    text-decoration: underline;
    font-weight: 850;
    /* margin-left: 1em; */
}
.dashboard--view_btn:hover{
  color: #d2120f;
}
.dashboard--header {
  display: flex;
  justify-content: space-between;
}
.dashboard--header_name {
  color: #475467;
  font-weight: 850;
  font-size: 2rem;
}
.dashboard--header_description {
  color: #afafaf;
}
.dashboard--header__generate {
  background-color: #d2120f;
  color: #fff;
  border: none;
  font-weight: 500;
  padding: 0.5em 1em;
  border-radius: 4px;
  display:flex;
  font-size: 1rem;
  align-items: center;
}
.dashboard--header__generate span{
    margin-left: 0.5em;
}
.dashboard-sc {
  display: flex;
  justify-content: center;
  margin-top: 3.5em;
  margin-bottom: 2rem;
  gap: 2em;
}
.dashboard-sc__btn {
  font-size: 1.2rem;
  color: #263238;
  background: none;
  padding: 10px 20px ;
  border-radius: 10px 10px 0 0 ;
  border: none;
  font-weight: 850;
  transition: all 250ms ease-in-out;
  
}
.dashboard--s__letters {
  padding: 1em 1.5em;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
}
.dashboard--s__letters-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}
.dashboard--s__letters-header-title {
  font-weight: 850;
  font-size: 1.1rem;
}
/* .dashboard-sent__lead{
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    margin: 2em auto;
} */
.dashboard--list-view,
.dashboard--grid-view {
  background: none;
  border: none;
}
.dashboard-sent__lead {
  font-size: 1.5rem;
  font-weight: 850;
  color: #475467;
  display: flex;
  justify-content: space-between;
}
.dashboard--img-view {
  display: flex;
}
.dashboard--img-view button {
  margin-left: 1.5em;
}
.dashboard--grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5em;
}
.dashboard--block {
  display: block;
}
.dashboard--block .dashboard--s__letters {
  margin: 2em auto;
}
.dashboard--grid {
  margin: 2em auto;
}
.dashboard--grid .dashboard--s__letters {
  margin: 0 auto;
}
.dashboard--img-view-grid {
  max-width: 800px;
  margin: 2em auto;
}
.dashboard--img-view-block {
  max-width: 600px;
  margin: 2em auto;
}
/* .dashboard--s__letters{
    border:
} */
.dashboard-error-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
}
.dashboard-error-grid {
  margin-top: 3em;
  grid-column: 1/4;
}
.dashboard-error-header,
.dashboard-error-leading,
.dashboard-error-btn{
    text-align: center;
    margin-top: 1em;
}
.dashboard-error-header{
    color: #04172A;
    font-size: 1.5rem;
    font-weight: 600;

}
.dashboard-error-grid a{
  display: block;
}
.dashboard-error-grid .dashboard-error-btn{
    background: #D2120F;
    border-radius: 10px;
    width: 100%;
    display: block;
    max-width: 260px;
    border: none;
    font-size: 1.1rem;
    margin: 1em auto;
    color: #fff;
    padding: 0.5em 0;
    font-size: 600;
}
.dashboard-error-block .dashboard-error-btn{
    background: #D2120F;
    border-radius: 10px;
    width: 260px;
    border: none;
    font-size: 1.1rem;
    margin: 1.5em auto;
    color: #fff;
    padding: 0.5em 0;
    font-size: 600;
}
.dashboard-sc__span{
    width: 16px;
    border-radius: 25px;
    background-color: #E5E5E5;
    margin-left: 0.5em;
    font-size: 12px;
    font-weight: 400;
}
.sc__btn--active{
  border: 1px solid #D2120F;
  border-bottom: none;
  color: #D2120F;
}
.sc__btn--active .dashboard-sc__span{
  background: #D2120F;
  color: white;
}
.dashboard-sc__btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.dasboard--delete__btn{
  border: none;
  background: none;
  margin-right: 1em;
}
.dashboard-trash:hover path{
  stroke: #d2120f;
}

.dashboard--img-view{
  display: flex;
}

@media screen and (max-width: 1023px) {
    .dashboard{
        padding: 5em 3em;
        font-size: 0.9em; 
    }
    .dashboard--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em;
      width: 100%;
      margin: 2em auto;
    }
    .dashboard--header_name {
        font-size: 1.4rem;
    }
    .dashboard--header__generate{
        font-size: 0.9rem;
    }
    .dashboard-sc button{
        font-size: 1.05rem;
    }
    .dashboard-sent__lead{
        font-size: 1.2rem;
    }
    .dashboard--img-view img{
        width: 18px;
    }
    .dashboard-error-block img{
        width: 200px;
    }
    .dashboard-error-header{
        font-size: 1.1rem;
    }
    .dashboard-error-block .dashboard-error-btn{
        font-size: 1rem;
    }
    .dashboard-error-grid{
        place-items: center;
        justify-items: center;
    }
    .dashboard-img{
        text-align: center;
    }
    .dashboard-img img{
        width: 200px;
    }
}
@media screen and (max-width: 500px) {
  .dashboard--img-view{
    display: none;
  }
  .dashboard--header{
      flex-direction: column;
      gap: 16px;

  }
  .dashboard--grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5em;
      width: 100%;
      margin: 2em auto;
    }
    .dashboard-sc{
      flex-wrap: wrap;
    }
}