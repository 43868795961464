.form_divlp {
  background-color: transparent !important;
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.form_divlp-submit {
  margin: 1em auto 1em !important;
  font-weight: 900;
}

@media screen and (max-width: 1060px) {
  .form_divlp-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 867px) {
  .form_divlp-container {
    padding: 2em 1.5em !important;
  }
}
