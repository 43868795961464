* {
  box-sizing: border-box;
}

.darkBG {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* position: absolute; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  padding: 2em;
  width: 300px;
  height: auto;
  background: #fff;
  color: #fff;
  z-index: 100000;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  color: #434343;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.header button {
  background: none;
  border: none;
}

.modal form {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.modal form label {
  padding-top: 0.5em;
}

.modal form input {
  padding: 8px;
  font-size: 16px;
  border-radius: 8px;
}

.modal form input[type="button"] {
  background: #d2120f;
  border-radius: 10px;
  margin-top: 0.5em;
  padding: 10px 20px;
  color: #fff;
  width: max-content;
  border: none;
  align-self: center;
}

.modal form input[type="button"]:hover {
  cursor: pointer;
}

.ScheduleContainer {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.ScheduledText {
  font-size: 24px;
  line-height: 32px;
}

.closeBtn {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal {
    width: 400px;
  }
}