* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}



.admin {
    width: 100%;
    margin: auto;
    position: relative;
    background-color: #ffffff;
}

.pagination_container .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled{
    color:#D2D2D2;
}

.pagination_container .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root{
    font-size:20px;
    font-weight: 500;
    color: #000000;
}



.pagination_container .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon{
    width: 38px;
    height: 28px;
    color: #D2120F;
}

.admin-main {
    width: 100%;
    margin: 125px auto auto auto;
}

.admininput{
    border: none;
    outline: none;
    border:1px solid #D0D5DD;
}

.admin-main h1 {
    width: 90%;
    margin: 0 auto;
    line-height: 44px;
    color: #475467;
}

::placeholder{
    color:#667085;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.admininput{
    border: none;
    outline: none;
    border:1px solid #D0D5DD;
    color:#667085;
}

::placeholder{
    color:#667085;
    font-size: 16px;
    font-weight: 400;
}

.table{
    display: grid;
    grid-template-columns: 43px minmax(200px, auto) minmax(200px, auto) minmax(400px, auto) minmax(260px, auto) 72px;
}



.total-sent {
    width: 90%;
    margin: 47px auto auto auto;
    height: 96px;
}

.total-sent-cont {
    width: 542px;
    height: 96px;
    display: flex;
    justify-content: space-between;
}

.total {
    background: rgba(245, 245, 245, 0.8);
    border-radius: 10px;
    border: 2px solid rgba(245, 245, 245, 0.8);
    border-left-color: #0F46D2;
    padding: 19px 27px 20px 20px;
    cursor: pointer;
}
.sent {
    width: 250px;
    height: 96px;
    background: rgba(245, 245, 245, 0.8);
    border-radius: 10px;
    border: 2px solid rgba(245, 245, 245, 0.8);
    border-left-color: #D2120F;
    padding: 19px 27px 20px 20px;
    cursor: pointer;
}

.total-cont, .sent-cont {
    display: flex;
    justify-content: space-between;
    height: 56px;
}

.total-cont-left p{
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #263238;
    font-family: 'Avenir';
}
.total-cont-left h3 {
    font-size: 42px;
    line-height: 20px;
    color: #0F46D2;
    margin-top: 15px;
    font-family: 'Avenir';
}

.total-cont-right {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #0F46D2;
    align-self: center;
}


.sent-cont-left p {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #263238;
}
.sent-cont-left h3 {
    font-size: 42px;
    color: #D2120F;
    margin-top: 15px;
    line-height: 20.66px;
}

.sent-cont-right {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #D2120F;
    align-self: center;
}
.main-bottom {
    width: 90%;
    margin: 79px auto auto auto;
}

.user-admin {
    width: 112px;
    display: flex;
    justify-content: space-between;
}
.user-admin span {
    cursor: pointer;
    display: inline-block;
}

.nunito{
    font-family: nunito;
}

.user-active, .admin-active {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 4px;
    border-bottom: 1px solid #D2120F;
    color: #04172A;
    font-family: 'Avenir';
}


.user-inactive, .admin-inactive {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 4px;
    color: #6B6C6C;
    border-bottom: 1px solid transparent;
    font-family: 'Avenir';
}

.table-container {
    width: 100%;
    background-color: #F7F7F7;
    margin-top: 19px;
    padding: 28px 64px;
}

.table-container-top {
   display: flex;
   justify-content: space-between;
}

.table-container-top-left {
  position: relative;
  width: 72px;
}

.table-container-top-right {
    display: flex;
    justify-content: space-between;
}
.table-top-checkbox {
    width: 18px;
    height: 18px;
    accent-color: #D2120F;
    margin:px 8px 0 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.tabletopchecklabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #292D32;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Avenir';

}

.table-container-top-right .search {
    margin-right: 16px;
    color: #263238;
    padding: 8px 20px 8px 33px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    outline: none;
    font-family: 'Avenir';
}

.bulk-act {
    width: 123px;
    padding: 8px 0 8px 8px;
    background-color: white;
    border-radius: 8px;
    outline: none;
    border: 1px solid white;
    margin-right: 8px;
    font-family: 'Avenir';
}

.apply {
    width: 75px;
    padding:8px 16px;
    border-radius: 8px;
    outline: none;
    border: 1px solid white;
    background-color: white;
    font-family: 'Avenir';
}

.table-container-main {
    overflow-x: auto;
    width: 100%;
    margin-top: 37px;
}

.table{
    color: #292D32;
    text-align: left;
}

.table > div{
    display: flex;
    align-items: center;
    padding:8px;
    background-color: white;
}



.table > div:last-child{
    justify-content: center;
}

.usercheck, .admincheck {
    accent-color: #D2120F;
    width: 18px;
    height: 18px;
}

@media screen and (max-width:540px){
    
    .table-container{
        padding:30px 20px;
    }
    .table-container-top{
        flex-direction: column;
        flex-wrap: wrap;
        gap: 16px;
    }

    .table-container-top-right{
        flex-wrap: wrap;
    }
    .table-container-top-left{
        margin-bottom: 16px;
    }
}


@media screen and (min-width:541px) and (max-width:785px){

    .admintable{
        display: grid;
        grid-template-columns: 43px minmax(200px, auto) minmax(200px, auto) minmax(400px, auto) minmax(260px, auto) 100px;
    }
}