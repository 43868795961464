.survey-modal,
.survey-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 100;
}
.survey-overlay {
    cursor: pointer;
    background: rgba(22, 22, 22, 0.8);
}
.free-trial {
  z-index: 100;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 90%;
  max-width: 600px;
  height: 90%;
  max-height: 689px;
  text-align: center;
  padding: 1em;
  border-radius: 16px;
}
.free--leading {
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0.5em 0;
}
.upgrade-freetrial {
  display: block;
}
.upgrade-freetrial button {
  display: block;
  width: 270px;
  margin: 0.6em auto;
  padding: 0.6em;
  background: #d2120f;
  /* Foundation /Red/red-6 */
  color: white;

  border: 1px solid #d2120f;
  border-radius: 8px;
}
.freetrial-img {
  height: 70%;
  width: 100%;
}

@media screen and (max-width: 700px) {
}
