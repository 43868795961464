@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700;800&display=swap');

:root {
  --text-color: #464646;
  /* --dark-text: #464646;
  --pink-text: #fff7f5; */
}

/* .App {
  background-color: var(--background);
  transition: all 1s;
  color: var(--text-primary);
} */

body {
  font-family: 'Nunito', sans-serif;
}

.landingpage-button Button {
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.dotted_love {
  position: absolute;
  width: 45%;
  top: 500px;
  left: 0;
}

.hero-content h1 {
  line-height: 1.1;
}

.section-one {
  background-color: #fff1f0;
  color: var(--text-color);
}

.section-two {
  background-color: #fff1f0;
}

.section-three {
  background-color: #f5f5f5;
}

.section-four {
  padding: 10px 23px;
  background-color: #f5f5f5;
}

.how_itworks {
  width: 100%;
}

.how_itworks img {
  width: 100%;
}

.section-five {
  background-color: #f5f5f5;
  padding: 30px;
}

.section-6 {
  background-color: #f5f5f5;
  /* z-index: 1; */
}
.testimonials {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  gap: 30px;
  padding: 30px;
  font-size: 18px;
  line-height: 1.5rem;
}

.testimonial-content {
  width: 352px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 40px;
}

@media screen and (min-width: 2560px) {
  .hero-content {
    margin-left: 300px;
  }
  .form_div {
    margin-left: 10px;
  }
}

@media screen and (min-width: 2000px) {
  .hero-content {
    margin-left: 280px;
  }
  .form_div {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1440px) {
  .form_div {
    width: 50%;
    margin-right: 2rem;
    padding-top: 7rem;
  }
}

@media screen and (min-width: 1024px) {
  .hero-content {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .hero-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media screen and (min-width: 768px) {
  .how_itworks:hover {
    transform: translate(0, -40px);

    transition-duration: 500ms;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media screen and (max-width: 997px) {
  .hero-content {
    width: 30rem;
  }
  .form_div {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .section-one {
    padding-top: 6rem;
  }
  .hero-content {
    width: 30rem;
  }
  .hero-content p {
    margin-top: 1rem;
  }
  .form_div {
    width: 80%;
  }
  .testimonials {
    display: flex;
    flex-direction: column;
  }
  .how_itworks {
    width: 60%;
    margin: 0 auto;
  }
  .how_itworks img {
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 670px) {
  .hero-section {
    width: 30rem;
  }
  .how_itworks {
    width: 80%;
  }
  .how_itworks img {
    width: 100%;
  }
  .section-6 p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 614px) {
  .hero-content {
    text-align: left;
    padding: 1rem;
  }
  .hero-content p {
    line-height: 1.8rem;
  }
  .section-three {
    padding-top: 3rem;
    padding-left: 0;
    padding-right: 0;
  }
  .how_to {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1.8rem;
    padding-bottom: 1.2rem;
  }
  .how_to p {
    font-size: 0.875rem !important;
  }
  .testimonials {
    gap: 40px;
  }
  .testimonial-content {
    gap: 15px;
  }
  .testimonial-content p {
    font-size: 0.9rem !important;
  }
  .testimonial-content span {
    font-size: 0.8rem;
    white-space: nowrap;
  }
  .section-6 {
    gap: 0;
    padding-top: 0;
  }
  .subscribe_link {
    padding: 0.5rem 3rem;
  }
  .popup {
    width: 80%;
    padding-top: 1rem;
  }
  .popup p {
    font-size: 1.3rem;
  }
  .popup span {
    height: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .hero-content {
    width: 90%;
  }
  .form_div {
    width: 100%;
  }
  .section-two {
    padding: 2rem 1rem;
  }
  .section-two h1 {
    font-size: 1.4rem;
  }
  .para1 {
    text-align: center;
    width: 100%;
    font-size: 0.875rem !important;
  }
  .section-four {
    padding: 30px 13px;
  }
  .how_itworks {
    width: 100%;
  }
  .how_itworks img {
    width: 70%;
  }
  .section-four div {
    /* padding: 1rem; */
    gap: 0.9rem;
  }
  .testimonials {
    padding: 0;
  }
}

@media screen and (max-width: 400px) {
  .hero-content {
    width: 90%;
  }
  .testimonial-content {
    width: 320px;
  }
}

/* Animations */
html,
body {
  height: 100%;
}

.wrapper {
  width: 100%;
  background: #f3f4f4;
  min-height: 100%;
  overflow: hidden;
  /* text-align: center; */
}

.x1 {
  left: 5%;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0.6;
  -webkit-animation: moveclouds 15s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: moveclouds 15s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}

.x2 {
  left: 25%;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.9;
  -webkit-animation: moveclouds 25s linear infinite,
    sideWays 5s ease-in-out infinite alternate;
  -moz-animation: moveclouds 25s linear infinite,
    sideWays 5s ease-in-out infinite alternate;
}
.x3 {
  left: 55%;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.8;
  -webkit-animation: moveclouds 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: moveclouds 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
}
.x4 {
  left: 72%;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.9;
  animation: moveclouds 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -webkit-animation: moveclouds 18s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  -moz-animation: moveclouds 18s linear infinite,
    sideWays 0zs ease-in-out infinite alternate;
}
.x5 {
  left: 88%;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.3;
  animation: moveclouds 7s linear infinite,
    sideWays 1s ease-in-out infinite alternate;
  -webkit-animation: moveclouds 7s linear infinite,
    sideWays 1s ease-in-out infinite alternate;
  -moz-animation: moveclouds 7s linear infinite,
    sideWays 1s ease-in-out infinite alternate;
}
.x6 {
  left: 33%;
  opacity: 1;
  -webkit-animation: moveclouds 2s linear infinite,
    swayWays 1s ease-in-out infinite alternate;
  animation: moveclouds 10s linear infinite,
    swayWays 9s ease-in-out infinite alternate;
}

@-webkit-keyframes moveclouds {
  0% {
    top: 500px;
  }
  100% {
    top: -500px;
  }
}

@keyframes moveclouds {
  0% {
    top: 500px;
  }
  100% {
    top: -500px;
  }
}

@-webkit-keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

@-keyframes swayWays {
  0% {
    transform: rotate(12deg);
    left: -0.3%;
  }
  33% {
    transform: rotate(-2deg);
    left: 0.7%;
  }
  100% {
    transform: rotate(0deg);
    left: -12%;
  }
}

/* //  HEART CODE */
.altheart {
  background: url(http://static.indigoimages.ca/2016/shop/113439_img25_twotoneheart.png)
    no-repeat center;
  width: 24px;
  height: 21px;
  position: relative;
  display: block;
}

.heart {
  position: relative;
}
.heart:before,
.heart:after {
  position: absolute;
  content: '';
  left: 18px;
  top: 0;
  width: 18px;
  height: 30px;
  background: #cc2022;
  -moz-border-radius: 20px 50% 0 0;
  border-radius: 30px 30px 0 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
