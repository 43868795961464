@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
:root {
  --main-1: #d2120f;
  --main-2: #d93937;
  --main-3: #e1615f;
  --main-4: #e88887;
  --main-5: #f0b0af;
  --main-6: #f6d0cf;
  --sec-1: #04172a;
  --sec-2: #04172ae5;
  --sec-3: #04172acc;
  --sec-4: #04172ab2;
  --sec-5: #04172a99;
  --sec-6: #04172a80;
}

html {
  scroll-behavior: smooth;
}

@tailwind components;
@tailwind utilities;
#root {
  overflow-x: clip;
}

.goog-te-banner-frame {
  display: none;
}

button {
  cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap');
* {
  font-family: 'Nunito', sans-serif;
}

* {
  font-family: 'Nunito', sans-serif;
}

a {
  text-decoration: none;
}

/* Contact Page  */

.submit-status,
.token-status,
.create-new-password {
  @apply invisible;
}

.danger {
  @apply !text-red-600;
}

.success {
  @apply !text-green-600;
}

/*
input,
button {
  background: none;
  border: none;
  font: inherit;
}

input {
  width: 100%;
}
*/
.spin-button-none {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.spin-button::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}
