:root {
  --nav-color: #fff;
  --nav-text: #464646;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700;800&display=swap');

*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

[data-theme='dark'] {
  --nav-color: #141b1f;
  --nav-text: #fff;
  --form-bg: #191919;
  --pink-text: #000;
  transition: all 1s;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  font-family: 'Nunito', sans-serif;
  background-color: var(--nav-color);
  max-height: 89px;
  transition: all 1s;
}
.nav-mobile {
  background-color: var(--nav-color);
}

.nav-btn {
  padding: 1em;
  background: #d2120f;
  color: var(--nav-color);
  border: 1px solid #d2120f;
  border-radius: 8px;
}
.nav-home-link {
  color: var(--nav-text);
  text-decoration: none;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--nav-text);
  padding: 1em 2.5em;
}

.nav-link {
  text-decoration: none;
  color: var(--nav-text);
  display: block;
}
.nav--links li {
  list-style-type: none;
}

.nav-logo {
  align-items: center;
  display: flex;
}
.nav-logo--img {
  max-width: 25px;
  max-height: 25px;
  margin-right: 0.7em;
}
.nav-dropdown {
  max-width: 24px;
  max-height: 24px;
}
.nav-logo__text {
  font-family: 'Nunito';
  font-size: 1.3rem;
  color: var(--nav-text);
  font-weight: 800;
}
.nav--links li {
  text-align: center;
}
.nav--links li:nth-of-type(1) {
  padding-top: 1em;
}
.nav-link {
  font-size: 1rem;
  padding: 0.9em 0;
  /* font-weight: 600; */
}
.nav-desktop {
  display: none;
}
.nav-deskdashboard {
  display: none;
}
.navm-logout {
  color: #d2120f;
  font-weight: 700;
}
.nav-dash {
  display: none;
}

@media screen and (min-width: 1024px) {
  .nav--links li:nth-of-type(1) {
    padding-top: 0em;
  }
  .navbar-container {
    padding: 1em 5.5em;
  }
  .nav-mobile {
    display: none;
  }
  .nav-desktop {
    display: block;
  }
  .nav--links {
    display: flex;
    align-items: center;
  }
  .nav-link {
    font-size: 1.3rem;
    font-weight: 700;

    /* padding: 1em 0; */
  }
  .nav--links li:not(:last-of-type) {
    margin-right: 2.8em;
  }
  .nav-dash {
    display: block;
  }
  .nav-logo--img {
    max-width: 42px;
    max-height: 42px;
  }
  .nav-logo__text {
    font-size: 1.8rem;
  }
  .nav-dash {
    background-color: transparent;
    border: none;
  }
  .nav-dash-arrow {
    width: 14px;
    height: 7px;
    margin-right: 14px;
  }
  .nav-login {
    font-weight: 900;
    background: #d2120f;
    border: 1px solid #d2120f;
    border-radius: 8px;
    padding: 0.4em 1.6em;
    color: #fff;
    font-family: 'Nunito';
    font-size: 1.1rem;
  }
  .nav-profile {
    width: 34px;
    height: 34px;
    border-radius: 34px;
    background-color: #e9e9e9;
  }
  .nav-dash {
    display: flex;
    position: relative;
    align-items: center;
  }
  .nav-link {
    padding: 0em 0;
  }
  .nav-deskdashboard {
    display: block;
    padding: 0 1em;
    border: 1px solid grey;
    position: absolute;
    bottom: -122px;
    left: -15px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 2px 2px 10px -1px rgba(52, 64, 84, 0.75);
    -webkit-box-shadow: 2px 2px 10px -1px rgba(52, 64, 84, 0.75);
    z-index: 10;
  }
  .dashboard-dbtn,
  .logout-dbtn {
    display: inline-block;
    color: #444444;
    font-size: 1rem;
    padding: 0.5em 0;
    background-color: white;
  }
  .logout-dbtn {
    color: #d2120f;
    font-weight: 900;
    border: none;
  }
  .nav-desktop-btn {
    cursor: pointer;
  }
}
