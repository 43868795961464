*,
::before,
::after {
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap');

:root {
  --footer-color: #04172a;
  --footer-text: #fff;
}

[data-theme='dark'] {
  --footer-color: #131b20;
  --footer-text: #fff;
  transition: all 1s;
}
.footer-container {
  padding: 2em 2.5em;
}
.footer-logo {
  display: flex;
  align-items: center;
}
.footer-logo__text {
  margin-left: 0.5em;
}
.footer-container,
.footer-containers {
  background-color: var(--footer-color);
  color: var(--footer-text);
  font-family: 'Nunito';
}
.footer-logo__text {
  font-size: 1.5rem;
  font-weight: 850;
}
.footer-mobile {
  display: flex;
  justify-content: space-between;
  margin-top: 3.1em;
}
.footer-links {
  margin-right: 0.9em;
  font-family: 'Nunito', sans-serif;
}
.footer-link {
  text-decoration: none;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.7em;
  font-family: 'Nunito', sans-serif;
  font-size: 0.9rem;
}
.footer-social--links {
  margin-top: 0.7em;
}
.footer-social--link {
  margin-right: 1.1em;
}
.footer-copyright {
  margin-top: 2em;
  padding-top: 1.5em;
  border-top: 0.869099px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.8rem;
  padding-bottom: 3em;
  font-family: 'Nunito', sans-serif;
}
.footer-desktop {
  display: none;
}
.footer-social--link img {
  max-width: 18px;
}

@media screen and (min-width: 1024px) {
  .footer-home {
    color: #fff;
    text-decoration: none;
  }
  .footer-container {
    padding: 2em 5.5em;
  }
  .footer-logo__text {
    font-size: 1.8rem;
  }
  .footer-mobile {
    display: none;
  }
  .footer-mobile--logo {
    display: none;
  }
  .footer-links {
    margin-right: 7em;
  }
  .footer-desktop {
    display: flex;
    padding-top: 4em;
    align-items: flex-start;
    justify-content: space-between;
  }
  .footer-link {
    font-size: 1.15rem;
    margin-bottom: 1em;
  }
  .footer-link:hover {
    color: #fff;
  }
  .footer-social--links {
    margin-top: -2em;
    display: flex;
  }
  .footer-social--link img {
    max-width: 23px;
  }
  .footer-home {
    color: #fff;
    text-decoration: none;
  }
  .footer-copyright {
    margin-top: 3em;
    font-size: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.8);
    padding-bottom: 3em;
  }
}
