.pgnot-container{
    width: 80%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100vh;
}
.pgnot-img{
    width: 100%;
    /* height: 70vh; */
    max-height: 70vh;
}
.pgnot-leading{
    font-style: italic;
    font-size: 1.6rem;
    text-align: center;
    margin: 1em 0;
}
.pgnot-text a{
    display: block;
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
}
.pgnot-button{
    background-color: #d2120f;
    width: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    margin: 0 auto;
    padding: 0.6em 0;
    border: none;
    border-radius: 10px;
}