.PricesContainer {
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4em;
}

.Prices {
  padding-block: 5rem;
  background: #fff;
  width: 100%;
  font-family: 'Avenir', sans-serif;
}

.Prices .TitleContainer {
  padding: 2em 3em 1em;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.Prices div h1 {
  text-align: center;
  color: #04172a;
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;
}

.Prices div .Subscription {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #464646;
  padding: 0 1em;
}

@media screen and (min-width: 900px) {
  .Prices {
    padding-top: 4.5rem;
  }

  .PricesContainer {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}
