.admin_form {
  width: 50%;
  max-width: 800px;
  margin: 0 auto !important;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(61, 60, 60, 0.25);
  border-radius: 8px;
  padding: 1em 2em;
  box-sizing: border-box;
}

.admin_form_login {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(61, 60, 60, 0.25);
  border-radius: 8px;
  padding: 1em 2em;
  box-sizing: border-box;
  padding-bottom: 10rem;
}

.input-error-border {
  border: 1px solid #f83f23;
}
.admin_input_ {
  border: 1px solid #d0d5dd;
}
.input {
  border-radius: 8px;
  padding: 10px 11px;
  outline: #475467;
  font-size: 14.5px;
}

@media screen and (min-width: 1350px) {
  .admin_form {
    width: 40%;
  }
}

@media screen and (max-width: 1023px) {
  .admin_form form {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .admin_form {
    width: 60%;
  }
  .admin_form form {
    width: 100%;
  }
}

@media screen and (max-width: 639px) {
  .admin_form {
    width: 90%;
  }
}

@media screen and (max-width: 390px) {
  .forgot,
  .dont_have {
    font-size: 14px;
  }
}
