.profile-photo_camera{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: 1px solid #464646;
}
.profile-photo-modal{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 1em; */
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
}
.profile-photo-form{
    position: relative;
    background-color: white;
    border-radius: 7px;
    width: 65%;
    max-width: 382px;
    /* height: 60%; */
    margin-top: 4em;
    height: 80%;
    max-height: 540px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    flex-direction: column;
}
.profile-form-container{
    display:flex;
    flex-direction: column-reverse;
    
    align-items: center;
    justify-content: center;
    /* height: 205px; */
    margin: 1em auto;
    

}
.photo-form-label{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #1E1E1E;
    height: 48px;
    width: 188px;
    border: 1px solid #434343;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 2rem;
    font-weight: 600;
}
.profile-photo-remove{
    font-weight: 600;
}
.preview-photo{
    width: 243px;
    height: 243px;
    margin: 1em auto;
    border-radius: 100%;
    background: #D9D9D9;
border: 1px solid #1E1E1E;
display: block;
margin: 0 auto;
}
.photo-form-input{
    display: none;
}
.photo-form-buttons{
    display: flex;
    justify-content: flex-end;
}

.profile-photo-remove{
    padding: 0.5em;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    color: cornflowerblue;
}
/* .profile-photo-save{
    margin-top: 1em;
} */
.profile-success{
    color: #5cb85c;
}
.profile-err{
    color: #d2120f;
}
.profile-photo-state{
    margin-top: 0.3em;
    text-align: center;
}
.photo-form-buttons {
    margin-top: 4em;
    padding-right: 1em;
}

.profile-photo-save{
    background: #D2120F;
    color: white;
    border-radius: 8px;
    width: 270px;
height: 48px;
margin: 0 auto;
margin-top: 2rem;
border: none;
display: block;
}
.profilename::placeholder{
    font-size: 0.8rem;
}

@media screen and (max-width: 450px){
    .profile-photo-form{
       width: 300px;
       height: 75vh;
    }
    /* .photo-form-input{
        

    } */
  
    .photo-form-buttons{
        display: flex;
        width: 100%;
        padding: 0;
        padding: 0 1em;
    }
   
    .photo-form-buttons button{
        padding: 0.7em 0;
        margin-bottom: 0.7em;
        margin-left: 0;
        width: 100%;
        border-radius: 5px;
        border: none;
        font-weight: 600;
        font-size: 1rem;
    }
}