.how_it_works {
  padding: 2rem;
}

.hiw_head {
  text-align: center;
}

/* .hiw_underline{
      margin: 0 auto;
      width: 50px;
      border-bottom: 2px solid #D2120F;
      margin-bottom: 0.6rem;
  } */

.hiw_head > h2 {
  color: #04172a;
  font-size: 2rem;
}

.hiw_body {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}

.hiw_button {
  margin-top: 1rem;
  background: none;
  border: 1.5px solid #d2120f;
  border-radius: 8px;
  display: block;
  color: #d2120f;
  padding: 0.5rem 1rem;
}

.hiw_body > .hiw_control {
  max-width: 25rem;
  min-height: 18rem;
  /* font-size: 1.2rem; */
  text-align: left;
  padding: 2rem 1rem;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: relative;
}

.hiw_body > .hiw_control > img {
  max-width: 100px;
}

.hiw_body > .hiw_control > h4 {
  padding: 0.5rem 0;
}

.hiw_control > p {
  padding: 1rem 0;
}

.hiw_control > p:nth-child(1) {
  position: absolute;
  right: 1rem;
  top: 0rem;
  padding: 1rem 0;
  font-size: 2rem;
  color: #d2120fc5;
  background: #ffffff;
  padding: 0.5rem;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
}
