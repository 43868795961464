.ParentContainer {
  background-color: #d2120f;
  border-radius: 24px;
}

.Container {
  border: 1px solid #d0d5dd;
  height: 502px;
  border-radius: 24px;
  color: #464646;
  padding: 1em 1em 2em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
  background: #fff;
}

.banner {
  text-align: center;
  display: block;
  width: 100%;
  border-radius: 24px 24px 0 0;
  /* box-shadow: 0 8px 4px -4px #d2120f; */
  padding: 1rem;
  z-index: 1;
  background: #d2120f;
  color: #fff;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
}

.heading {
  padding: 1em 1em 2em;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f5f5f5;
}

.Container p {
  padding: 1rem 0;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
}

.Container ul {
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 70%;
}

.Container ul li {
  list-style-type: disc;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.Container button {
  background: #da3d3a;
  border: 1px solid #da3d3a;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 0px 0px 4px #fbe7e7;
  border-radius: 8px;
  width: 65%;
  padding: 10px 8px;
  align-self: center;
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 24px;
  }

  .Container {
    z-index: 1000;
  }

  .banner {
    text-align: center;
    display: block;
    width: 100%;
    border-radius: 24px 24px 0 0;
    /* box-shadow: 0 8px 4px -4px #d2120f; */
    padding: 1rem;
    z-index: 1;
    background: #d2120f;
    color: #fff;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
  }

  .banner::before {
    content: "";
    position: relative;
    background-color: transparent;
    bottom: -50px;
    height: 50px;
    width: 25px;
    border-top-left-radius: 25px;
    box-shadow: 0 -25px 0 0 #d2120f; 
  }
}
