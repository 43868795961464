.help-center-header {
  min-height: 60vh;
  background-color: #f6d0cf;
}

.help-center-header-search input {
  border: none;
  border-radius: 8px 0 0 8px;
}

.help-center-header-search button {
  border: none;
  border-radius: 0px 8px 8px 0;
}

.help-center-header-search button img {
  width: 18px;
}

.loader-box {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75em;
}

.loader {
  width: 20px;
  height: 20px;
  display: block;
  background-color: transparent;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-radius: 50%;
  animation: loader 1s infinite;
}

.spinner-box {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.spinner-box .spinner {
  display: block;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-right: 2px solid #000;
  border-left: 2px solid #000;
  border-radius: 50%;
  animation: loader 0.5s infinite;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
