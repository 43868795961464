.list-style li {
  list-style-type: disc;
}
/* text input styles for career page */
.application-label {
  display: flex;
  flex-direction: column;
  color: #344054;
  margin-top: 20px;
}
.application-input::placeholder {
  color: #667085;
}
.application-label input {
  border: 1px solid #d0d5dd;
  outline: none;
  height: 50px;
  width: 100%;
  padding-left: 32px;
  border-radius: 10px;
  margin-top: 8px;
}
.send_application {
  background-color: #d2120f;
  color: white;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 15px 28px;
  border-radius: 10px;
  margin-top: 80px;
  border: none;
}
.cancel_application {
  border: 1px solid #d2120f;
  background-color: #fff;
  color: #d2120f;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 15px 60px;
  border-radius: 10px;
  margin-top: 14px;
  margin-bottom: 20px;
}
