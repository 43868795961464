.profilelabel{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.profilename{
    border: 1px solid #d0d5dd;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    outline: none;
}

.profilename:focus{
    border: 1px solid #da3d3a;
    box-shadow: 0px 0px 0px 4px #fbe7e7;
}

.profilename::placeholder{
    color: #667085;
}

.securityname{
    border: 1px solid #d0d5dd;
    border-radius: 5px;
    width: 100%;
    height: 40px;
}
.securityname::placeholder{
    font-size: 0.8rem;

}
.securitylabel{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}