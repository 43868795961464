.modal_Container {
    font-family: 'Avenir';
    justify-content: center;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    background: white;
    box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.08);
    column-gap: 6em;
    margin: 2em 1em;
    row-gap: 2em;
    justify-content: center;
}

.modal_Container>article>img {
    width: 80%;
    object-fit: cover;
}

.modal_content {
    padding: 1em 2em;
    -webkit-animation: scale-up-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation-delay: 0.5s;
}

.modal_content>article {
    display: flex;
    gap: 2em;
    padding-bottom: 1em;
    justify-content: space-between;
}

.modal_content>article>h2 {
    font-size: 900;
    color: var(--main-1);
    font-size: 25px;
}

.modal_content>article>p {
    font-size: 400;
    align-self: center;
    cursor: pointer;
    color: #464646;
}

.modal_content>ul {
    list-style: none;
}

.modal_content>ul>li {
    padding: 0.5em 0;
    font-size: 18px;
}

.modal_content>ul>li:last-child {
    padding-bottom: 1em;
}


/* responsiveness */

@media (max-width: 900px) {}


/* animations */

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}