.previewletter--container {
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  margin-top: 40px;
}

.previewletter--body {
  /* padding-top: 5em; */
}

.previewletter--header {
  background: #ffffff;
  box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  font-weight: 900;
  width: 379px;
  max-width: 90%;
  text-align: center;
  margin: 0 auto;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.previewletter-mobilescreenlocation-button {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewletter-mobilescreenlocation-button a {
  display: block;
}

.previewletter-mobilescreenlocation-button a:nth-of-type(1) {
  /* margin-right: 1em; */
}
.prevbtn-schedule {
  padding: 0.5em;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  background: #ffffff;
  padding: 0.5em 1.5em;
  font-weight: 800;
  gap: 5px;
  color: #344054;
}
.prev-schedule {
  width: 15px;
  margin-right: 0.4em;
}
.previewletter-mobilescreenlocation-button button {
  background: #ffffff;
  padding: 0.5em 2.5em;
  max-width: 112px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 0.9rem;
  justify-content: center;
}

.previewletter-mobilescreenlocation-button img {
  width: 15px;
}

.previewletter-mobilescreenlocation-button img:nth-of-type(1) {
  margin-right: 0.5em;
}

.previewletter-mobilescreenlocation-button img:nth-of-type(1) {
  margin-left: 0.5em;
}

.previewletter-form--container {
  width: 90%;
  max-width: 700px;
  background-color: #fffefe;
  margin: 0 auto;
  position: relative;
  border: 1px solid #f8b7c8;
  box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
}

.previewletter-form--header {
  font-weight: 900;
  text-align: center;
}

.previewletter--input {
  width: 100%;
  height: 90vh;
  font-size: 0.9rem;
  border: none;
  line-height: 143%;
  text-align: center;
  color: #464646;
  background: transparent;
  caret-color: #d2120f;
}
.previewletter--input::-webkit-input-placeholder {
  color: #464646;
}
.previewletter--input::placeholder {
  color: #464646;
}

.previewletter--input:disabled {
  /* background-color: #fff; */
  -webkit-text-fill-color: #464646;
  opacity: 1;
  color: #464646;
}

.previewletter--input:focus {
  outline: none;
}

.previewletter-love {
  position: absolute;
  left: 0px;
  top: 0px;
}

.prev-edit {
  width: 15px;
}

.prevbtn-edit {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: #d2120f;
  font-weight: 850;
  font-size: 1rem;
}

.previewletter-functions {
  display: flex;
  justify-content: space-between;
  margin: 1.5em 0;
}

.previewletter--share_downloadmobile {
  max-width: 100%;
  margin: 0 auto;
  padding: 2em 1em;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 10px;
  justify-content: center;
}

.previewletter--share_downloaddesktop {
  display: none;
}

.prevdown-btn,
.prevbtn-schedule,
.generate-btn {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 0.5em 1em;
  color: #344054;
  font-weight: 850;
  display: flex;
  max-width: 125px;
  /* margin-right: 1em; */
  display: flex;
  align-items: center;
}
.prevshare-btn {
  background: #d2120f;
  border: none;
  border-radius: 8px;
  padding: 0.5em 1em;
  color: #fff;
  font-weight: 850;
  display: flex;
  max-width: 125px;
  margin-right: 1em;
  display: flex;
  gap: 10px;
  align-items: center;
}

.prevshare-btn img {
  margin-right: 0.6em;
}

.preview--sociallinks_clip {
  padding: 1em;
  margin: -1em 3em 2em;
  display: inline-block;
  border-radius: 8px;
  box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.08);
}

.preview--sociallinks_clip span {
  margin-right: 1.2em;
}

.btn-schedule {
  background: none;
  border: none;
}

.prev--copied {
  background-color: #d2120f;
  color: #fbfbfb;
  font-weight: 800;
  position: absolute;
  border-radius: 6px;
  bottom: 50px;
  padding: 0.3em;
}

.preview--sociallinks_clipdesktop {
  display: none;
}

.preview--sociallinks_clip {
  display: inline-block;
}

.copied {
  background: none;
  border: none;
}

.copied img {
  width: 25px;
}
/* .edit_form-container {
  width: 700px;
} */
.edit_form-container {
  width: 90%;
  max-width: 700px;
  margin: auto;
}
.edit {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: white;
  color: #344054;
  font-weight: 500;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}
.edit:hover {
  border: 1px solid #d2120f;
}
/* .previewletter-form--container{
  background-image: url('../../public/heart.svg');
  background-repeat: no-repeat;
  background-position: left top;
} */

@media screen and (min-width: 600px) {
  /* .previewletter-form--container{
    background-image: url('../../public/heart.svg'), url('../../public/heart2.png');
    background-repeat: no-repeat;
    background-position: left top, 120% 120%;
  }  */

  .copied img {
    width: 35px;
  }

  .previewletter--input {
    width: 100%;
    height: 700px;
    font-size: 1rem;
    border: none;
    line-height: 143%;
  }

  .previewletter--header {
    font-size: 1.4rem;
  }

  .previewletter-form--header {
    font-size: 1.4rem;
  }

  .previewletter--container {
    background-position: 80% 0;
  }

  .previewletter--share_download {
    max-width: 496px;
    font-size: 1rem;
  }

  .previewletter--share_downloadmobile {
    display: none;
  }

  .previewletter--body {
    /* padding-top: 7em; */
  }

  .previewletter--form {
    position: relative;
  }

  .previewletter--share_downloaddesktop {
    display: block;
  }

  .preview--sociallinks_clipdesktop {
    display: block;
    position: absolute;
    /* top: 20px; */
    bottom: 0px;
    left: 30px;
  }

  .preview--sociallinks_clipdesktop span,
  button {
    display: block;
  }

  .preview--sociallinks_clip {
    display: none;
  }

  .previewletter--share_downloaddesktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    /* margin-left: 2em; */
  }
}
@media screen and (min-width: 1023px) {
  .edit_form-container {
    width: 90%;
    max-width: 700px;
  }
}
