.writer {
  background-color: #fbfbfb;
  padding-bottom: 5em;
  background-repeat: no-repeat;
  background-position: 90% top;
  padding-top: 8em;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
}
.writer__form-container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(61, 60, 60, 0.25);
  border-radius: 9px;
  padding: 2em 3em;
  box-sizing: border-box;
}
.writer__form-leading {
  text-align: center;
}
.writer__input-container {
  margin-bottom: 1em;
  width: 100%;
  max-width: 436px;
  margin: 0 auto;
  margin-bottom: 1.3em;
}
.writer__input-container label,
.writer__input-container input {
  display: block;
}
.writer__input-container input {
  padding: 0.5em 0.5em;
  width: 100%;
  border: 1.5px solid #d0d5dd;
  border-radius: 5px;
  font-size: 1rem;
}
.writer__textarea {
  padding: 1em;
  border: 1.5px solid #d0d5dd;
  border-radius: 5px;
  font-size: 1rem;
}
.writer-form__select {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0.7em 0.5em;
  border-radius: 5px;
  font-size: 0.9rem;
  border: 1.5px solid #d0d5dd;
  /* background: #F3F5F6; */
}
select:invalid {
  background: #f3f5f6;
}
.writer__input-container textarea {
  width: 100%;
  height: 107px;
}
.writer--keyword__container {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 1em;
}
.writer--keyword__button {
  padding: 0.5em 1.1em;
  border-radius: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.wkf .writer--keyword__button:nth-child(odd) {
  color: #fff;
  background-color: #d2120f;
}
.wkf .writer--keyword__button:nth-child(even) {
  border: 1px solid #d2120f;
  color: #d2120f;
}
.wks .writer--keyword__button:nth-child(even) {
  color: #fff;
  background-color: #d2120f;
}
.wks .writer--keyword__button:nth-child(odd) {
  border: 1px solid #d2120f;
  color: #d2120f;
}
.wkt .writer--keyword__button:nth-child(even) {
  color: #fff;
  background-color: #d2120f;
}
.wkt .writer--keyword__button:nth-child(odd) {
  border: 1px solid #d2120f;
  color: #d2120f;
}
.writer__form-submit {
  padding: 0.4em 0;
  width: 180px;
  display: block;
  background: #d2120f;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 1.1rem;
  margin: 1em auto 5em;
  font-weight: 900;
}
.writer__form-error {
  color: #d2120f;
  text-align: center;
  font-size: 0.85rem;
}
.writer__form-retry {
  border: 1.5px solid #d2120f;
  display: block;
  border-radius: 5px;
  color: #d2120f;
  background-color: #fff;
  width: 100px;
  padding: 0.5em 0;
  margin: 0 auto 1em;
}
.cursor_disabled {
  cursor: not-allowed;
}
.writer--keyword__description {
  text-align: center;
  font-weight: 800;
  font-size: 1.25rem;
  margin: 2em 0 1.7em;
}
.writer__form-leading {
  font-weight: 850;
  font-size: 1.4rem;
  margin: 1em 0 1.5em;
}

@media screen and (max-width: 860px) {
  .writer--keyword__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 700px) {
  .writer {
    font-size: 0.9rem;
  }
  .writer__form-leading {
    font-size: 1.2rem;
  }
  .writer--keyword__description {
    font-size: 1.05rem;
  }
  .writer__form-submit {
    font-size: 1rem;
  }
}
