/*
===============  
faq
===============  
*/

@font-face {
  font-family: 'Avenir';
  src: url('../public/AvenirLTStd-Roman.otf');
}

@font-face {
  font-family: 'Avenir-light';
  src: url('../public/AvenirLTStd-Book.otf');
}

@font-face {
  font-family: 'Avenir-bold';
  src: url('../public/AvenirLTStd-Black.otf');
}

.faq {
  background-color: var(--clr-container);
  margin-top: 1.5rem;
  border-radius: 5px;
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.title-button-container h2 {
  font-family: 'Avenir';
  font-style: normal;
  margin: 0;
  color: #493030;
}

.title-button-container button {
  font-size: 1rem;
  color: var(--clr-btn);
  background-color: var(--clr-btn-background);
  border-radius: 50%;
  border: none;
  align-self: center;
  display: block;
}

.faq p {
  color: rgba(28, 23, 23, 0.75);
  margin: 0px;
  margin-top: 1rem;
  font-family: 'Avenir';
  font-style: normal;
}

.ask {
  background-image: url(./assets/faq.png);
  background-position: center;
  color: #fff;
}

/*
  ===============  
  media query
  ===============  
  */

@media (min-width: 1000px) {
  .title-button-container button {
    padding: 0px;
    width: 30px;
    height: 30px;
  }
}
