:root {
  --sitem-1: #464646;
  --sitem-2: #04172a;
}
/* @font-face {
  font-family: 'Avenir';
  src: url('../../public/AvenirLTStd-Roman.otf');
} */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap');

*,
::before,
::after {
  margin: 0;
  padding: 0;
}

.sitemaptitlebg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../assets/Rectangle3.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.sitemap {
  margin: 2.2em 0 9em;
  font-family: 'Nunito', sans-serif;
  font-size: 0.85rem;
  padding: 2em 3.2em;
  width: 100%;
}
/* .hero--leading {
  font-weight: 850;
  font-size: 1.7rem;
  text-align: center;
  margin-bottom: 2.1em;
  color: var(--sitem-1);
} */
.hero--img {
  text-align: center;
}
.hero--img img {
  width: 100%;
  height: 100%;
  max-width: 682px;
  max-height: 465px;
  margin: 0 auto;
}
.links-section {
  margin-top: 3em;
}
.links--leading {
  font-family: 'Nunito';
  font-weight: 600;
  color: var(--color-2);
  margin: 2em 0 0.9em;
}
.site-link {
  text-decoration: none;
  display: block;
  font-size: 1rem;
  color: var(--sitem-1);
}
.site-link:hover {
  color: #d2120f;
}
.site-link:not(:last-of-type) {
  margin-bottom: 0.54em;
}

@media screen and (min-width: 1024px) {
  .sitemap {
    padding-left: 5.5em;
    padding-right: 5.5em;
    padding-left: 6.7em;
    padding-right: 6.7em;
    font-size: 0.85rem;
  }

  .links--leading {
    font-size: 1.75rem;
    margin-bottom: 0.7em;
  }
  .site-link {
    font-size: 1.2rem;
  }
  .links-section {
    font-size: 2.3rem;
    display: grid;
    column-gap: 2.125em;
    grid-template-columns: repeat(4, 1fr);
  }
  .site-link:not(:last-of-type) {
    margin-bottom: 0.8em;
  }
}

@media screen and (min-width: 541px) and (max-width: 800px) {
  .links-section {
    font-size: 2rem;
    display: grid;
    column-gap: 2em;
    grid-template-columns: repeat(2, 1fr);
  }

  .site-link {
    font-size: 1.1rem;
  }
  .links--leading {
    font-size: 1.6rem;
    margin-bottom: 0.6em;
  }
}

@media screen and (min-width: 801px) and (max-width: 1023px) {
  .links-section {
    font-size: 2rem;
    display: grid;
    column-gap: 2em;
    grid-template-columns: repeat(3, 1fr);
  }

  .site-link {
    font-size: 1.125rem;
  }
  .links--leading {
    font-size: 1.7rem;
    margin-bottom: 0.65em;
  }
}

@media screen and (max-width: 640px) {
  .links-section {
    display: grid;
    gap: 60px;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .links--leading {

    font-size: 1.5rem;

    margin-bottom: 0.65em;

  } 

  .site-link {

    font-size: 1rem;

    }
}

@media screen and (max-width: 520px) {
  .links-section {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
  }

  .links--leading {
    font-size: 1.5rem;
    margin-bottom: 0.65em;
  } 
  .site-link {
    font-size: 0.9rem;
    }
}
