.policy-one {
  background: url('/public//Rectangle\ 3.svg') no-repeat;
  background-position: center;
  background-size: contain;
  color: #ffffff;
}
.policy {
  background-image: url(../assets/faq.png);
  background-position: center;
  color: #fff;
}

.text_div p {
  line-height: 2 !important;
  font-size: 1rem !important;
}

.text_div h2 {
  font-size: 1.3rem !important;
  line-height: 2rem !important;
  font-weight: 900 !important;
}

.info__ {
  margin-top: 1.5rem !important;
}

@media (min-width: 1280px) {
  .text_div p {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
  .text_div h2 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
}

@media (min-width: 640px) {
  .text_div p {
    font-size: 1.125rem !important;
    line-height: 2rem !important;
  }

  .text_div h2 {
    font-size: 1.4rem !important;
    line-height: 2rem !important;
  }
}

@media screen and (max-width: 500px) {
  .text_div p {
    font-size: 1rem !important;
    line-height: 1.7 !important;
  }
  .text_div h2 {
    font-size: 1.2rem !important;
    line-height: 2rem !important;
  }
}

@media screen and (max-width: 800px) {
  .text_div {
    margin-top: 1.6rem !important;
  }
}

@media screen and (max-width: 476px) {
  .policy-one {
    background-position: center;
    background-size: cover;
    background-size: inherit;
  }
}

@media screen and (max-width: 476px) {
  .policy-one {
    background-position: center;
    background-size: cover;
  }

}
